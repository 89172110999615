import { useState } from "react"
import { addPropertyControls, ControlType } from "framer"

import FbShareButton from "https://framer.com/m/FbShareButton-SwPr.js@Zr6RovqC4Vf1eKoChIHu"
import XShareButton from "https://framer.com/m/XShareButton-l6wq.js@6Q4HMxkffLLxITSjHXv8"
import LkShareButton from "https://framer.com/m/LkShareButton-b9iE.js@NcrBDBLV6iUi35EfIiq3"
import PiShareButton from "https://framer.com/m/PiShareButton-3jej.js@p12WzBfKJtQfU5W1HhNv"
import CopyShareButton from "https://framer.com/m/CopyShareButton-WM6i.js@nAF3Mg9oLBQJO4hqgKgv"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function ShareWidget(props) {
    const {
        iconproperties,
        backgroundcolor,
        radius,
        padding,
        borderproperties,
        showX,
        showFb,
        showLk,
        showPi,
        showCp,
    } = props

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: props.widgetgap,
            }}
        >
            {showX && (
                <XShareButton
                    iconColor={iconproperties.color}
                    iconWidth={iconproperties.width}
                    backgroundColor={backgroundcolor}
                    borderRadius={radius}
                    padding={padding}
                    borderColor={borderproperties.color}
                    borderWidth={borderproperties.width}
                    borderStyle={borderproperties.style}
                />
            )}
            {showFb && (
                <FbShareButton
                    iconColor={iconproperties.color}
                    iconWidth={iconproperties.width}
                    backgroundColor={backgroundcolor}
                    borderRadius={radius}
                    padding={padding}
                    borderColor={borderproperties.color}
                    borderWidth={borderproperties.width}
                    borderStyle={borderproperties.style}
                />
            )}
            {showLk && (
                <LkShareButton
                    iconColor={iconproperties.color}
                    iconWidth={iconproperties.width}
                    backgroundColor={backgroundcolor}
                    borderRadius={radius}
                    padding={padding}
                    borderColor={borderproperties.color}
                    borderWidth={borderproperties.width}
                    borderStyle={borderproperties.style}
                />
            )}
            {showPi && (
                <PiShareButton
                    iconColor={iconproperties.color}
                    iconWidth={iconproperties.width}
                    backgroundColor={backgroundcolor}
                    borderRadius={radius}
                    padding={padding}
                    borderColor={borderproperties.color}
                    borderWidth={borderproperties.width}
                    borderStyle={borderproperties.style}
                />
            )}
            {showCp && (
                <CopyShareButton
                    iconColor={iconproperties.color}
                    iconWidth={iconproperties.width}
                    backgroundColor={backgroundcolor}
                    borderRadius={radius}
                    padding={padding}
                    borderColor={borderproperties.color}
                    borderWidth={borderproperties.width}
                    borderStyle={borderproperties.style}
                />
            )}
        </div>
    )
}

ShareWidget.defaultProps = {
    iconColor: "#FFFFFF",
    iconWidth: 16,
    backgroundColor: "#212121",
    borderRadius: 16,
    padding: 16,
    borderColor: "#222222",
    borderWidth: 0,
    borderStyle: "solid",
    widgetgap: "8",
    text: "Hello World!",
    showX: true,
    showFb: true,
    showLk: true,
    showPi: true,
    showCp: true,
}

addPropertyControls(ShareWidget, {
    showX: {
        type: ControlType.Boolean,
        title: "Show X",
        defaultValue: true,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    showFb: {
        type: ControlType.Boolean,
        title: "Show Facebook",
        defaultValue: true,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    showLk: {
        type: ControlType.Boolean,
        title: "Show Linkedin",
        defaultValue: true,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    showPi: {
        type: ControlType.Boolean,
        title: "Show Pinterest",
        defaultValue: true,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    showCp: {
        type: ControlType.Boolean,
        title: "Show Copy",
        defaultValue: true,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    iconproperties: {
        type: ControlType.Object,
        title: "Icons",
        controls: {
            color: {
                type: ControlType.Color,
                title: "Color",
                defaultValue: "#FFFFFF",
            },
            width: {
                type: ControlType.Number,
                title: "Width",
                defaultValue: 16,
            },
        },
    },
    backgroundcolor: {
        type: ControlType.Color,
        title: "Fill",
        defaultValue: "#212121",
    },
    radius: {
        type: ControlType.FusedNumber,
        title: "Radius",
        defaultValue: 16,
        toggleKey: "isMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["topLeft", "topRight", "bottomRight", "bottomLeft"],
        valueLabels: ["NW", "NE", "SE", "SW"],
        min: 0,
    },
    padding: {
        type: ControlType.FusedNumber,
        title: "Padding",
        defaultValue: 16,
        toggleKey: "isMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["topLeft", "topRight", "bottomRight", "bottomLeft"],
        valueLabels: ["NW", "NE", "SE", "SW"],
        min: 0,
    },
    borderproperties: {
        type: ControlType.Object,
        title: "Border",
        controls: {
            color: {
                type: ControlType.Color,
                title: "Color",
                defaultValue: "#222222",
            },
            width: {
                type: ControlType.Number,
                title: "Width",
                defaultValue: 0,
            },
            style: {
                type: ControlType.Enum,
                title: "Style",
                options: ["Solid", "Dashed", "Dotted", "Double"],
                defaultValue: "Solid",
            },
        },
    },
    widgetgap: {
        type: ControlType.Number,
        title: "Gap",
        defaultValue: 8,
    },
})
